<template>
  <article
    :class="[$style.announce, { focus: !wasAnimated }]"
    @animationend="wasAnimated = true"
  >
    <h4 :class="$style.title">
      {{ t('title') }}
    </h4>

    <i18n-t :class="$style.text" tag="p" keypath="text.content">
      <template #highlighted>
        <strong>{{ t('text.highlighted') }}</strong>
      </template>
    </i18n-t>

    <div :class="$style.misc">
      <div :class="$style.status">
        <status-icon :class="$style.statusIcon" />
        <span :class="$style.statusText">{{ t('status') }}</span>
      </div>

      <ui-button
        :class="$style.button"
        to="/pages/hypertask"
        target="_blank"
        fill
        theme="accent"
      >
        {{ t('button') }}
      </ui-button>
    </div>
  </article>
</template>

<i18n>
{
  "ru": {
    "title": "Как теперь выполнять\nзадания в PARI Pass?",
    "text": {
      "content": "Читай новый гайд и забирай\nпромокод на {highlighted}",
      "highlighted": "2000 коинов!"
    },
    "status": "Обновление!",
    "button": "Читать"
  }
}
</i18n>

<script lang="ts" setup>
import UiButton from '~/components/ui/button/index.vue'
import StatusIcon from './status-icon.svg'

const { t } = useI18n()

const wasAnimated = useState(() => false)
</script>

<style lang="scss" module>
.announce {
  border-radius: 1.2em;
  padding: 2em;
  background: url(./bg.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1.6em;

  @include hiDPI {
    background-image: url(./bg@2x.jpg);
  }
}

.title {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.2;
  white-space: pre-line;
}

.text {
  font-size: 1.6em;
  line-height: 1.25;
  white-space: pre-line;
}

.misc {
  margin-top: 3.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  color: yellow;
  display: flex;
  gap: 0.6em;
  align-items: center;
}

.statusIcon {
  width: 2.2em;
  height: 2.2em;
}

.statusText {
  font-family: var(--primary-font), sans-serif;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.button {
  min-width: 8.8em;
}
</style>
