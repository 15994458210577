<template>
  <article
    :class="classes"
    :style="taskStyleVars"
    @click="openTask"
    :data-task-preview-id="source.id"
  >
    <div :class="$style.container">
      <div :class="$style.picture" v-if="source.content.image_url">
        <img
          :src="source.content.image_url.small"
          loading="lazy"
          decoding="async"
          alt=""
        />
      </div>

      <div :class="$style.timerWrap" v-if="showTimer">
        <task-common-timer :class="$style.timer" :value="taskTimeout" />
      </div>

      <div :class="$style.head">
        <div :class="$style.reward" v-if="source.rewards.length">
          <template v-for="reward in sortedRewards">
            <ui-coin
              :key="reward.id"
              v-if="reward.resource.source?.id === 'coin'"
              :code="getCoinCode(reward.resource.source_id)"
              :value="reward.amount"
              size="medium"
            />
            <ui-reward
              v-else
              :key="reward.id + '_reward'"
              :img="reward.resource.image_url?.small"
              :title="reward.resource.name"
            />
          </template>
        </div>
        <div
          :class="$style.partner"
          v-if="source.content.partner && source.content.partner.image_url"
        >
          <ui-link
            :href="source.content.partner.link"
            v-if="source.content.partner.link"
          >
            <img :src="source.content.partner.image_url.small" alt="" />
          </ui-link>
          <img v-else :src="source.content.partner.image_url.small" alt="" />
        </div>
      </div>

      <div :class="$style.description">
        {{ source.content.title }}
      </div>

      <task-common-progress :value="taskProgress" :class="$style.progress" />

      <div :class="$style.details">
        <task-common-status :status="status" :class="$style.status" />

        <div :class="$style.completed" v-if="source.stat">
          <ui-icon name="ready" :class="$style.icon" />
          <span :class="$style.counter">{{ shortText }}</span>
        </div>

        <task-common-priority-button
          :class="$style.priorityButton"
          :task-id="source.id"
          :member-status="source.memberStatus"
        />
      </div>
    </div>
  </article>
</template>

<script setup>
import UiCoin from '~/components/ui/coin/index.vue'
import UiLink from '~/components/ui/link/index.vue'
import TaskCommonStatus from '~/components/task/common/status.vue'
import shortener from '~/utils/shortener'
import TaskCommonTimer from '~/components/task/common/timer'
import {
  getSortedRewards,
  getTaskProgress,
  getTaskStatus,
  getTaskStyleVars,
  getTaskTimeout,
  taskShowTimer
} from '~/components/task/helpers/helpers'
import TaskCommonProgress from '~/components/task/common/progress.vue'
import TaskCommonPriorityButton from '~/components/task/common/priority-button.vue'
import UiIcon from '~/components/ui/icon/index.vue'
import UiReward from '~/components/ui/reward/index.vue'
import { useSettingsStore } from '~/stores/common/settings'

const $style = useCssModule()
const $props = defineProps({
  source: { type: Object, required: true }
})

const $emit = defineEmits(['open'])

const taskStyleVars = computed(() => getTaskStyleVars($props.source))
const showTimer = computed(() => taskShowTimer($props.source))
const taskTimeout = computed(() => getTaskTimeout($props.source))
const status = computed(() => getTaskStatus($props.source.memberStatus))
const taskProgress = computed(() => getTaskProgress($props.source.memberStatus))
const shortText = computed(() => shortener($props.source.stat.completed))
const classes = computed(() => [
  $style.card,
  status.value === 'completed' && $style.completed
])

const settingStore = useSettingsStore()
const { getCoinCode } = settingStore
const openTask = () => $emit('open', $props.source)

const sortedRewards = computed(() => getSortedRewards($props.source.rewards))
</script>

<style lang="scss" module>
.card {
  background: var(--style-bg);
  color: var(--style-text);
  padding: 2em;
  border-radius: 1.2em;
  cursor: pointer;
  transition: transform 0.2s ease;
  border: var(--style-border);

  @include up(md) {
    &:hover {
      transform: scale(1.05);
    }
  }
}

.picture {
  border-radius: 0.5em;
  overflow: hidden;
  margin-bottom: 2em;
  position: relative;
  padding-bottom: 53%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4em;
}

.reward {
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  width: 100%;
  margin-bottom: 1em;
}

.description {
  font-weight: 400;
  font-size: 1.5em;
  line-height: 146%;
}

.timerWrap {
  position: relative;
}

.timer {
  position: absolute;
  left: 50%;
  top: -0.6em;
  transform: translate(-50%, -100%);
}

.partner {
  margin-left: 3em;

  img {
    object-fit: contain;
    max-height: 3em;
    width: 100%;
    max-width: 9em;
  }
}

.details {
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    width: 2.2em;
    height: 2.2em;
    margin-left: 1.5em;
    color: rgba(255, 255, 255, 0.5);
  }

  .completed {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);

    .icon {
      margin-right: 0.3em;
    }
  }

  .counter {
    font-weight: 500;
    font-size: 1.6em;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.5);
  }
}

.progress {
  margin-top: 2.6em;
}

.status {
  flex: 1 1;
}

.completed {
  cursor: default;

  &:hover {
    transform: scale(1);
  }

  .container {
    opacity: 0.2;
  }
}

.priorityButton {
  margin-left: 1em;
}
</style>
