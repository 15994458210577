<template>
  <div :class="classes">
    <button
      :class="$style.button"
      type="button"
      :disabled="page === 1"
      @click="page--"
    >
      <ui-icon :class="$style.icon" name="chevron-left-thick" />
    </button>

    <span :class="$style.info">
      <span :class="$style.t">{{ page }} / {{ pageCount }}</span>
    </span>

    <button
      :class="$style.button"
      type="button"
      :disabled="page === pageCount"
      @click="page++"
    >
      <ui-icon
        :class="[$style.icon, $style.rotated]"
        name="chevron-left-thick"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import UiIcon from '~/components/ui/icon/index.vue'

const props = withDefaults(
  defineProps<{
    pageCount: number
    padded?: boolean
  }>(),
  {
    padded: false
  }
)

const page = defineModel<number>({
  required: true
})

const cssModule = useCssModule()

const classes = computed(() => [
  cssModule.pagination,
  {
    [cssModule.padded]: props.padded
  }
])
</script>

<style lang="scss" module>
.pagination {
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;

  &.padded {
    border-radius: 6em;
    padding: 0.4em;
    background: var(--app-color-black);
    box-shadow: 0 1.3em 1.3em rgba(0, 0, 0, 0.25);
  }
}

.button {
  border-radius: 50%;
  width: 4em;
  height: 4em;
  background: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: color, background;
  transition-duration: 0.3s;

  &:disabled {
    pointer-events: none;
    color: rgba(255, 255, 255, 0.5);
    background: transparent;
  }

  @include hover-focus {
    color: var(--app-color-black);
    background: var(--app-active-color);
  }
}

.icon {
  width: 2em;
  height: 2em;

  &.rotated {
    transform: rotate(180deg);
  }
}

.info {
  min-width: 3.6em;
  text-align: center;
}

.t {
  font-size: 1.6em;
  line-height: 1.5;
}
</style>
