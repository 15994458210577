<template>
  <ui-link :class="$style.banner" to="/battlepass" draggable="false">
    <div :class="$style.info">
      <h3 :class="$style.heading">
        <i18n-t keypath="getBonus">
          <template #seasonPass>
            <span>{{ t('seasonPass') }}</span>
          </template>
        </i18n-t>
      </h3>
      <div :class="$style.level">
        <div :class="$style.levelInner">60lvl</div>
      </div>
    </div>

    <picture>
      <source
        media="(min-width: 567px)"
        type="image/webp"
        srcset="./assets/bg@2x.webp 2x, ./assets/bg.webp 1x"
      />
      <source
        media="(min-width: 567px)"
        srcset="./assets/bg@2x.png 2x, ./assets/bg.png 1x"
      />
      <source
        type="image/webp"
        srcset="./assets/bg-sm@2x.webp 2x, ./assets/bg-sm.webp 1x"
      />
      <img
        src="./assets/bg-sm.png"
        srcset="./assets/bg-sm.png 2x, ./assets/bg-sm.png 1x"
        alt=""
        draggable="false"
        :class="$style.bg"
      />
    </picture>
    <picture>
      <source
        media="(min-width: 567px)"
        type="image/webp"
        srcset="./assets/rewards@2x.webp 2x, ./assets/rewards.webp 1x"
      />
      <source
        media="(min-width: 567px)"
        srcset="./assets/rewards@2x.png 2x, ./assets/rewards.png 1x"
      />
      <source
        type="image/webp"
        srcset="./assets/reward@2x.webp 2x, ./assets/reward.webp 1x"
      />
      <img
        src="./assets/reward.png"
        srcset="./assets/reward@2x.png 2x, ./assets/reward.png 1x"
        alt=""
        draggable="false"
        :class="$style.rewards"
      />
    </picture>
  </ui-link>
</template>

<i18n>
{
  "ru": {
    "seasonPass": "Season pass",
    "getBonus": "Не забывай забирать награды в {seasonPass}"
  }
}
</i18n>

<script lang="ts" setup>
import UiLink from '~/components/ui/link/index.vue'

defineOptions({
  name: 'PassPromoBanner'
})

const { t } = useI18n()
</script>

<style lang="scss" module>
.banner {
  position: relative;
  height: 8em;
  overflow: hidden;
  width: 100%;
  display: block;

  img {
    max-width: 100%;
  }

  @include down(md) {
    height: 7em;
  }

  @include down(sm) {
    padding: 0;
    height: 14.5em;
    align-items: flex-start;
  }
}

.info {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  font-family: var(--primary-font), sans-serif;
  left: 0.8em;
  top: 50%;
  transform: translateY(-50%);

  @include down(sm) {
    top: 1.2em;
    bottom: 1.4em;
    left: 1.5em;
    transform: none;
  }
}

.heading {
  font-size: 2.4em;
  font-weight: 700;
  text-transform: uppercase;
  width: 16em;

  span {
    color: rgba(189, 238, 0, 1);
  }

  @include down(md) {
    font-size: 1.6em;
  }

  @include down(sm) {
    width: 12em;
    font-size: 1.8em;
  }
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.rewards {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2.9em;
  width: 68%;

  @include down(sm) {
    right: 0.4em;
    width: auto;
  }
}

.level {
  display: none;
  height: 3.5em;
  width: 17.5em;
  border-radius: 8.2em;
  text-transform: none;
  padding-right: 1.3em;
  margin-top: auto;
  background-color: rgba(25, 32, 44, 1);

  @include down(sm) {
    display: block;
  }
}

.levelInner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(288.61deg, #bdee00 2.73%, #00c7b1 62.51%);
  border-radius: 8.2em;
  color: rgba(0, 0, 0, 1);
  font-size: 2.3em;
  font-weight: 600;
  width: 90%;
  height: 100%;
  padding-right: 1.4em;
}
</style>
